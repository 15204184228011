<template>
    <highcharts class="hc" :options="options" ref="chart"></highcharts>
</template>

<script>
import Vuex from "vuex";

export default {
    props: {
        chartTitle: String,
    },
    data() {
        return {
            options: {
                chart:{
                    backgroundColor: 'white',
                },
                title: {
                    text: this.chartTitle,
                    x: -20 //center
                },
                subtitle: {
                    text: 'Source : LOAC',
                    x: -20
                },
                xAxis: {
                    type: 'datetime',
                    labels: {
                        format: '{value:%Y-%m-%d}',
                    },
                    dateTimeLabelFormats: { // don't display the dummy year\
                        day: '%e. %b',
                    },
                    title: {
                        text: 'Timestamp (Day)'
                    },
                    // plotLines: [{
                    //     value: 0,
                    //     color: '#c93838',
                    //     width: 2,
                    //     zIndex: 2
                    // }],
                },
                yAxis: {
                    type: 'logarithmic',
                    minorTickInterval: 0.1,
                    accessibility: {
                        rangeDescription: 'Range: 0.1 to 1000'
                    },
                    title: {
                        text: 'Concentration (nb/Liter)'
                    },
                    min:1,
                    max: 10000000
                },
                tooltip: {
                    valueSuffix: ' nb/L'
                },
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                    borderWidth: 0
                },
                credits: {
                    enabled: false
                },
            },
            loaded:false
        }
    },
    methods:{
        getRandomArbitrary(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        getColor(){
            return ['rgb(0, 172, 237)','rgb(12, 245, 116)','rgb(255, 22, 84)']
        },
        setSeries(){
            let data = this.reports.yearlyConc
            this.loaded = true
            let colors = this.getColor()
            return [
                {
                    name: 'Particules from 0,2 to 1 µm - ground',
                    data: data['PM1'],
                    type: 'spline',
                    color: colors[0]
                }, {
                    name: 'Particules from 1 to 2,5 µm - ground',
                    data: data['PM2_5'],
                    type: 'spline',
                    color: colors[1]
                },{
                    name: 'Particules from 2,5 to 10 µm - ground',
                    data: data['PM10'],
                    type: 'spline',
                    color: colors[2]
                }
            ]
        }
    },
    created() {
        this.options.series = this.setSeries()
    },
    computed:{
        ...Vuex.mapGetters('report',{
            reports:'reports',
            loadingStatus:'loadingStatus'
        }),
        isLoaded(){
            return this.loaded
        }
    },
    watch:{
        'this.reports'(){
            this.options.series = this.setSeries()
        },
    },
};
</script>

<template>
    <b-row>
        <b-sidebar
                id="sidebar-no-header"
                aria-labelledby="sidebar-no-header-title"
                backdrop
                shadow="lg"
        >
            <template #default="{ hide }">
                <div class="p-3">
                    <h4 id="sidebar-no-header-title" class="clr-dark-grey rounded p-2">
                        TOOL MENU
                        <b-icon aria-hidden="true" icon="tools"></b-icon>
                    </h4>
                    <hr>
                    <b-modal
                            v-if="showModal"
                            v-model="show"
                            centered
                            on
                            size="xl"
                            title="DATA MANAGER - Available Files"
                    >
                        <FileModal v-if="showModal" :selectedSensor="selected"></FileModal>
                    </b-modal>
                    <div
                            class="my-1 mt-5 rounded border-bottom p-2 menu-item-style"
                            @click="show=!show"
                    >
                        <b-icon aria-hidden="true" class="float-left" icon="bar-chart-fill"></b-icon>
                        DATA MANAGER
                    </div>
                    <div
                            class="my-1 rounded border-bottom p-2 menu-item-style"
                            @click="logout()"
                    >
                        <b-icon aria-hidden="true" class="float-left" icon="power"></b-icon>
                        LOGOUT
                    </div>
                    <b-button block class="fixed-bottom rounded-0" variant="dark" @click="hide">
                        <b-icon aria-hidden="true" class="float-left" icon="x"></b-icon>
                        CLOSE SIDEBAR
                    </b-button>
                </div>
            </template>
        </b-sidebar>
        <b-col cols="12" xl="8" class="my-4 mx-auto">
            <p class="h2 clr-dark-grey text-center">AIR QUALITY DASHBOARD
                <b-icon animation="fade" icon="cloud-fill"></b-icon>
            </p>
            <p class="h3 clr-light-grey text-center">DATA FOLLOW-UP </p>
        </b-col>
        <b-col v-if="sensors && sensors.length > 1" cols="10" class="bg-white rounded p-4 shadow mb-4 mx-auto">
            <b-row class="p-0 m-0 mb-5">
                <b-col cols="12">
                    <h3 class="text-center p-0 m-0"> Report Informations</h3>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6" class="my-auto">
                    <h5>
                        <b-icon icon="wifi" /> Sensor ID : </h5>
                    <h5 class="clr-blue font-weight-bold">
                         {{ selected }}
                    </h5>
                    <h5>
                        <b-icon icon="house" /> City:
                    </h5>
                    <h5 class="clr-blue font-weight-bold" v-if="selectInformations[0].sensorAddress.locality">
                         <span>{{ selectInformations[0].sensorAddress.locality }},</span> <span>{{ selectInformations[0].sensorAddress.countryShortName }}</span>
                    </h5>
                </b-col>
                <b-col cols="6">
                    <b-form-group class="mb-3" v-if="sensors && sensors.length > 1">
                        Select another sensor :
                        <b-form-select
                                v-model="selected"
                                :options="sensorsOptions"
                                text-field="name"
                                value-field="item"
                        ></b-form-select>
                    </b-form-group>
                    <b-form-group>
                        <label for="atmo-datepicker">Choose a date</label>
                        <b-form-datepicker id="atmo-datepicker" v-model="actualDate" class="mb-2" :disabled="!ready" :locale="locale"></b-form-datepicker>
                    </b-form-group>
                </b-col>
            </b-row>
<!--            <b-row v-else>-->
<!--                <div class="col-xl-12">-->
<!--                    <p class="h5">Actual Sensor</p>-->
<!--                    <p class="clr-blue h3">{{ selected }}</p>-->
<!--                    <p class="clr-blue h4">-->
<!--                        City:-->
<!--                        {{ selectInformations[0].sensorAddress.locality }},-->
<!--                        {{ selectInformations[0].sensorAddress.countryShortName }}-->
<!--                    </p>-->
<!--                </div>-->
<!--            </b-row>-->
<!--            <div>-->

<!--            </div>-->
            <!--            {{sensors[0]}}-->
            <!--            <div>-->
            <!--                <b-modal-->
            <!--                        v-model="show"-->
            <!--                        title="Modal Variants"-->
            <!--                        on-->
            <!--                        centered-->
            <!--                        size="xl"-->
            <!--                        hide-backdrop-->
            <!--                        v-if="showModal"-->
            <!--                >-->
            <!--                    <FileModal v-if="showModal" :selectedSensor="selected"></FileModal>-->
            <!--                </b-modal>-->
            <!--            </div>-->
        </b-col>
        <div v-if="ready" class="col-xl-8 mx-auto mb-5 shadow">
            <div class="m-4">
                <h4 class="clr-blue my-auto text-center font-weight-bold">Report for {{ dateValue }}</h4>
            </div>
        </div>
        <div class="col-xl-8 mx-auto mb-5 shadow rounded" v-if="getSensorsById(selected) !== undefined && getSensorsById(selected).sensorParameters.isMobile === true">
            <div v-if="ready">
                <h3 class="clr-blue my-2 font-weight-bolder">Concentration in Altitude
                    <b-icon id="info-icon" href="#" icon="info-circle-fill" tabindex="0"></b-icon>
                    <b-popover placement="rightbottom" target="info-icon" triggers="hover" variant="primary">
                        Concentration in altitude <span class="text-danger">by 20 meter increments</span><strong> on the
                        last day of flight</strong>
                    </b-popover>
                </h3>
                <ChartAltitude/>
                <hr>
            </div>
            <div v-else class="col-xl-8 mx-auto mb-5">
                <h3 class="clr-blue my-2 font-weight-bolder">Concentration in Altitude (Content Loading)</h3>
                <b-card>
                    <b-skeleton animation="fade" width="85%"></b-skeleton>
                    <b-skeleton animation="fade" width="55%"></b-skeleton>
                    <b-skeleton animation="fade" width="70%"></b-skeleton>
                </b-card>
            </div>
        </div>
<!-- CONC-->
        <div v-if="ready" class="col-xl-8 mx-auto mb-5 shadow rounded">
            <div v-if="getSensorsById(selected) !== undefined && getSensorsById(selected).sensorParameters.isMobile === true">
                <h3 class="clr-blue my-2 font-weight-bolder">
                    Concentration by size range
                    <b-icon id="chart-size-range-mobile" href="#" icon="info-circle-fill" tabindex="0"></b-icon>
                    <b-popover placement="rightbottom" target="chart-size-range-mobile" triggers="hover" variant="primary">
                        Concentration by <span class="text-danger">particle size range</span><strong> on the last day of
                        data</strong>.
                    </b-popover>
                </h3>
                <div class="my-3">
                    <ChartDailyCon :key="componentKey" chart-title="Daily Concentration - 0,2µm to 1µm" data-type="pm1"></ChartDailyCon>
                </div>
                <div class="my-5">
                    <ChartDailyCon :key="componentKey" chart-title="Daily Concentration - 1µm to 2,5µm" data-type="pm2_5"></ChartDailyCon>
                </div>
                <div class="">
                    <ChartDailyCon :key="componentKey" chart-title="Daily Concentration - 2,5µm to 10µm" data-type="pm10"></ChartDailyCon>
                </div>
                <hr>
            </div>
            <div v-else>
                <h3 class="clr-blue my-2 font-weight-bolder">
                    Concentration by size range
                    <b-icon id="chart-size-range-1" href="#" icon="info-circle-fill" tabindex="0"></b-icon>
                    <b-popover placement="rightbottom" target="chart-size-range-1" triggers="hover" variant="primary">
                        Concentration by <span class="text-danger">particle size range</span><strong> on the last day of
                        data</strong>.
                    </b-popover>
                </h3>
                <div class="my-3">
                    <ChartDailyCon
                            :key="componentKey"
                            chart-title="0,2µm to 1µm"
                            data-type="pm1"
                            :is-mobile="false"
                    ></ChartDailyCon>
                </div>
                <div class="my-5">
                    <ChartDailyCon
                            :key="componentKey"
                            chart-title="1µm to 2,5µm"
                            data-type="pm2_5"
                            :is-mobile="false"
                    ></ChartDailyCon>
                </div>
                <div class="">
                    <ChartDailyCon
                            :key="componentKey"
                            chart-title="2,5µm to 10µm"
                            data-type="pm10"
                            :is-mobile="false"
                    ></ChartDailyCon>
                </div>
                <hr>
            </div>
        </div>
        <div v-else class="col-xl-8 mx-auto mb-5 shadow rounded">
            <h3 class="clr-blue my-2 font-weight-bolder">Concentration by size range (Content Loading)</h3>
            <b-card>
                <b-skeleton animation="fade" width="85%"></b-skeleton>
                <b-skeleton animation="fade" width="55%"></b-skeleton>
                <b-skeleton animation="fade" width="70%"></b-skeleton>
            </b-card>
        </div>
<!-- MASS-->
        <div v-if="ready" class="col-xl-8 mx-auto mb-5 shadow rounded">
            <div v-if="getSensorsById(selected) !== undefined && getSensorsById(selected).sensorParameters.isMobile === true">
                <h3 class="clr-blue my-2 font-weight-bolder">
                    Concentration by Mass
                    <b-icon id="chart-mass-mobile" href="#" icon="info-circle-fill" tabindex="0"></b-icon>
                    <b-popover placement="rightbottom" target="chart-mass-mobile" triggers="hover" variant="primary">
                        Concentration by <span class="text-danger">particle mass</span><strong> on the last day of
                        data</strong>.
                    </b-popover>
                </h3>
                <div class="my-3">
                    <ChartDailyCon
                            :key="componentKey"
                            chart-title="Daily Particle Mass - PM 2,5"
                            y-title="Particle Mass (microgrammes m-3)"
                            data-type="mass2_5">
                    </ChartDailyCon>
                </div>
                <div class="my-5">
                    <ChartDailyCon
                            :key="componentKey"
                            chart-title="Daily Particle Mass - PM 10"
                            y-title="Particle Mass (microgrammes m-3)"
                            data-type="mass10">
                    </ChartDailyCon>
                </div>
                <hr>
            </div>
            <div v-else>
                <h3 class="clr-blue my-2 font-weight-bolder">
                    Concentration by Mass
                    <b-icon id="chart-mass-1" href="#" icon="info-circle-fill" tabindex="0"></b-icon>
                    <b-popover placement="rightbottom" target="chart-mass-1" triggers="hover" variant="primary">
                        Concentration by <span class="text-danger">particle mass</span><strong> on the last day of
                        data</strong>.
                    </b-popover>
                </h3>
                <div class="my-3">
                    <ChartDailyCon
                            :key="componentKey"
                            chart-title="Daily Particle Mass - PM 2,5"
                            data-type="mass2_5"
                            y-title="Particle Mass (microgrammes m-3)"
                            :is-mobile="false"
                    ></ChartDailyCon>
                </div>
                <div class="my-5">
                    <ChartDailyCon
                            :key="componentKey"
                            chart-title="Daily Particle Mass - PM 10"
                            data-type="mass10"
                            y-title="Particle Mass (microgrammes m-3)"
                            :is-mobile="false"
                    ></ChartDailyCon>
                </div>
                <hr>
            </div>
        </div>
        <div v-else class="col-xl-8 mx-auto mb-5 shadow rounded">
            <h3 class="clr-blue my-2 font-weight-bolder">Concentration by size range (Content Loading)</h3>
            <b-card>
                <b-skeleton animation="fade" width="85%"></b-skeleton>
                <b-skeleton animation="fade" width="55%"></b-skeleton>
                <b-skeleton animation="fade" width="70%"></b-skeleton>
            </b-card>
        </div>
<!-- END-->
        <div v-if="ready" class="col-xl-8 mx-auto shadow rounded">
            <div v-if="getSensorsById(selected) !== undefined && getSensorsById(selected).sensorParameters.isMobile === true">
                <h3 class="clr-blue my-2 font-weight-bolder">
                    Concentration per day
                    <b-icon id="chart-current-year-mobile" href="#" icon="info-circle-fill" tabindex="0"></b-icon>
                    <b-popover placement="rightbottom" target="chart-current-year-mobile" triggers="hover" variant="primary">
                        Concentration in particle per <span class="text-danger">day</span><strong> on the last year of
                        data</strong>.
                        The measured values were measured <span class="text-danger">on the ground</span>
                    </b-popover>
                </h3>
                <ChartYearlyCon chart-title="Measurement history from 0.2 to 10 µm on the ground"></ChartYearlyCon>
                <hr>
            </div>
            <div v-else>
                <h3 class="clr-blue my-2 font-weight-bolder">
                    Concentration per day
                    <b-icon id="chart-current-year" href="#" icon="info-circle-fill" tabindex="0"></b-icon>
                    <b-popover placement="rightbottom" target="chart-current-year" triggers="hover" variant="primary">
                        Concentration in particle per <span class="text-danger">day</span><strong> on the last year of
                        data</strong>.
                        The measured values were measured <span class="text-danger">on the ground</span>
                    </b-popover>
                </h3>
                <ChartYearlyCon chart-title="Measurement history from 0.2 to 10 µm on the ground"></ChartYearlyCon>
                <hr>
            </div>
        </div>
        <div v-else class="col-xl-8 mx-auto mb-5">
            <h3 class="clr-blue my-2 font-weight-bolder">Concentration per day on the current year (Content
                Loading)</h3>
            <b-card>
                <b-skeleton animation="fade" width="85%"></b-skeleton>
                <b-skeleton animation="fade" width="55%"></b-skeleton>
                <b-skeleton animation="fade" width="70%"></b-skeleton>
            </b-card>
        </div>
    </b-row>
</template>

<script>
import ChartAltitude from "@/components/charts/ChartAltitude";
import ChartDailyCon from "@/components/charts/ChartDailyCon";
import ChartYearlyCon from "@/components/charts/ChartYearlyCon";
import Vuex from 'vuex';
import FileModal from "@/components/fileManagement/FileModal";
import moment from "moment";

export default {
    name: 'AirQualityDashboard',
    components: {
        FileModal,
        ChartYearlyCon,
        ChartAltitude,
        ChartDailyCon
    },
    data() {
        return {
            optionSmall: {},
            optionMedium: {},
            optionBig: {},
            ready: false,
            selected: null,
            sensorsOptions: [],
            componentKey: 0,
            show: false,
            actualDate: new moment().format('Y-M-D'),
            locale: 'en-US'
        }
    },
    methods: {
        ...Vuex.mapActions('sensor', {
            setSensors: 'setSensors',
        }),
        ...Vuex.mapActions('report', {
            setReports: 'setReports'
        }),
        ...Vuex.mapActions('login', {
            logout: 'logout'
        }),
        queryForSensors() {
            let query = {}
            query.type = 'pollution'
            return query
        },
        queryForReports() {
            let query = {}
            query.sensor = this.selected
            query.date = this.dateValue
            return query
        },
        setSensorsOptions() {
            for (let i = 0; i < this.sensors.length; i++) {
                this.sensorsOptions.push(
                    {
                        item: this.sensors[i].serialNumber,
                        name: this.sensors[i].serialNumber
                    }
                )
            }
        }
    },
    computed: {
        ...Vuex.mapGetters('sensor', {
            sensors: 'sensors',
            getSensorsById: 'getSensorsById'

        }),
        ...Vuex.mapGetters('report', {
            reports: 'reports'
        }),
        isReady() {
            return this.ready
        },
        showModal() {
            return this.show
        },
        selectInformations(){
            return this.sensors.filter(item => item.serialNumber === this.selected)
        },
        dateValue() {
            return moment(this.actualDate).format('LL')
        }
    },
    async created() {
        await this.setSensors(this.queryForSensors())
        if (this.sensors.length > 0) {
            this.setSensorsOptions()
            this.selected = this.sensors[0].serialNumber
        }

        await this.setReports(this.queryForReports()).then(() => {
            this.ready = true;
        })
    },
    watch: {
        selected: async function () {
            this.ready = false;
            await this.setReports(this.queryForReports()).then(() => {
                this.ready = true;
                this.componentKey += 1
            })
        },
        dateValue: async function () {
            this.ready = false;
            await this.setReports(this.queryForReports()).then(() => {
                this.ready = true;
                this.componentKey += 1
            })
        }
    },
}
</script>

<style lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.menu-item-style {
  cursor: pointer;
  color: #696C71;
  background: white;

  &:hover {
    color: white;
    background: #696C71;
  }
}
</style>

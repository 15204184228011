<template>
    <div class="row full-view-height align-items-center align-content-center justify-content-center bg-lighter-grey">
        <div class="row col-xl-12 justify-content-center mt-1" v-if="authStatus">
            <div class="col-auto">
                {{authStatus.message}}
            </div>
        </div>
        <div class="row col-xl-12  align-content-center align-items-center text-center login-card">
            <div class="col-12 row my-5">
                <div class="col-xl-12">
                    <img src="@/assets/logo.svg" style="max-height: 100px;"/>
                </div>
                <div class="col-12 font-weight-bolder" style="font-size: calc(20px + 0.5vw); color: #28AAEA;min-font-size: 20px">Air Quality Dashboard</div>
            </div>
            <div class="col-12 row align-self-center text-center justify-content-center mb-5">
                <div v-if="isTokenValid" class="row justify-content-center">
                    <div class="col-12">
                        <p class="clr-blue text-center">Choose a new password</p>
                    </div>
                    <div class="col-md-8 font-weight-bolder">
                        <b-form @submit.prevent="changePassword()">
                            <b-form-group>
                                <b-form-input
                                    id="input-1"
                                    v-model="password"
                                    type="password"
                                    required
                                    placeholder="password"
                                    class="login-input"
                                ></b-form-input>
                            </b-form-group>
                            <b-button type="submit" variant="primary" style="background-color: #28AAEA">
                                Validate
                            </b-button>
                        </b-form>
                    </div>
                </div>
                <div v-else class="row justify-content-center">
                    <p class="col-12 float-left clr-blue">Validation of the received code</p>
                    <div class="col-12 font-weight-bolder">
                        <b-form @submit.prevent="confirmResetToken()" inline class="justify-content-center">
                            <b-form-input id="input-token" v-model="token" type="number" min="100000" max="999999"
                                          required class="login-input"></b-form-input>
                            <b-button type="submit" variant="primary" class="mt-5 col-12" style="background-color: #28AAEA">
                                Validate
                            </b-button>
                        </b-form>
                    </div>
                </div>
            </div>
		</div>
	</div>

</template>

<script>
	import Vuex from 'vuex'

	export default {
		name: "PasswordResetForm",
		data() {
			return {
				password: '',
				passwordType: 'password',
                token: null
			}
		},
		methods: {
			...Vuex.mapActions('login', {
				resetPassword: 'resetPassword',
                confirmToken: 'confirmToken'
			}),
			async changePassword() {
                let query = this.generateQueryString()
				await this.resetPassword(query);
			},
            async confirmResetToken() {
                await this.confirmToken(this.token)
            },
            generateQueryString(){
                let data = []
                data.push(this.token)
                let fields = new FormData();
                fields.set('password', this.password)
                data.push(fields)
                return data
            },
			capitalize (value){
				return this.$options.filters.capitalize(value)
			}
		},
        computed: {
            ...Vuex.mapGetters('login', {
                authStatus: 'authStatus',
                isTokenValid: 'getIsResetTokenValid'
            }),
        },
	}
</script>

<style lang="scss" scoped>

    .login-card {
      margin: 15vh 0;
      padding: 1vh 0;
      max-width: 500px;
      background-color: white;
      border-radius: 10px;
    }
    .login-input {
      background-color: white;
      border: none;
      color:$light-grey;
      box-shadow: none !important;
      border-bottom: 1px solid $light-grey;
      border-radius: 0;
      &:active{
        color: #28AAEA;
        border-bottom: 1px solid #28AAEA;
        box-shadow: none !important;
      }
      &:focus{
         color: #28AAEA;
         border-bottom: 1px solid #28AAEA;
         box-shadow: none !important;
       }
    }

    .language-selector__container {
      select {
        background-color: white;
        border: 1px solid rgb(197, 197, 197);
        border-radius: 5px;
        box-sizing: border-box;
        color: #3E3D40;
        display: block;
        font-family: SourceSansPro, Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-weight: 600;
        height: 40px;
        min-width: 80px;
        padding: 0 20px 0 16px;
        outline: none;
      }
      label {
        position:relative
      }

      label:after {
        content:'';
        left: 54px;
        top: 17px;
        padding:0 0 2px;
        position:absolute;
        pointer-events:none;
        width: 0;
        height: 0;
        border-left: 3.5px solid transparent;
        border-right: 3.5px solid transparent;
        border-top: 5px solid #007fd8;
        z-index: 100;
      }

      label:before {
        content:'';
        left: 56px;
        //right: 8px;
        top: 10px;
        width: 20px;
        height: 20px;
        background: white;
        position: absolute;
        pointer-events: none;
        display: block;
        z-index: 1;
      }
    }

    .hr-separator{
      border-top: 1px solid $light-grey;
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>

<template>
    <div class="row full-view-height align-items-center align-content-center justify-content-center bg-lighter-grey">
        <div class="row col-xl-12 justify-content-center mt-1" v-if="authStatus">
            <div class="col-auto">
                {{authStatus.message}}
            </div>
        </div>
        <div class="row col-xl-12  align-content-center align-items-center text-center login-card">
            <div class="col-xl-12 row my-5">
                <div class="col-xl-12">
                    <img src="@/assets/logo.svg" style="max-height: 100px;"/>
                </div>
                <div class="col-xl-12 font-weight-bolder" style="font-size: calc(20px + 0.5vw); color: #28AAEA;min-font-size: 20px">Air Quality Dashboard</div>
            </div>
            <div class="col-xl-12 row align-self-center text-center justify-content-center mb-5">
                <div class="col-sm-8 font-weight-bolder">
                    <transition name="fade" mode="out-in">
                        <b-form @submit.prevent="resetPassword()" v-if="showForgotPassword" key="askForReset">
                            <b-form-group>
                                <b-form-input
                                    id="input-1"
                                    v-model="email"
                                    type="email"
                                    required
                                    placeholder="Email"
                                    class="login-input"
                                ></b-form-input>
                            </b-form-group>
                            <b-button type="submit" variant="primary" style="background-color: #28AAEA" >Forgot your password ?</b-button>
                        </b-form>
                        <b-form @submit.prevent="connexion()" v-else key="login">
                            <b-form-group>
                                <b-form-input
                                    id="input-1"
                                    v-model="email"
                                    type="email"
                                    required
                                    placeholder="Enter your email"
                                    class="login-input"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group class="mb-5">
                                <b-form-input
                                    id="input-2"
                                    v-model="password"
                                    type="password"
                                    required
                                    placeholder="Enter your password"
                                    class="login-input"
                                ></b-form-input>
                            </b-form-group>

                            <b-button type="submit" variant="primary" style="background-color: #28AAEA" v-if="!connexionIsLoading">Login</b-button>
                            <b-button type="submit" variant="primary" style="background-color: #28AAEA" v-else>Connection<b-spinner small></b-spinner></b-button>
                        </b-form>
                    </transition>
                </div>

            </div>
            <div class="col-xl-12 row add-cursor">
                <p @click="showForgotPassword = !showForgotPassword" class="col-xl-12 clr-light-grey justify-content-end" v-if="!showForgotPassword">Forgot your password ?</p>
                <p @click="showForgotPassword = !showForgotPassword" class="col-xl-12 clr-light-grey justify-content-end" v-else>Cancel</p>
            </div>
        </div>
    </div>

</template>

<script>
import Vuex from 'vuex'

export default {
    name: "ConnexionForm",
    data() {
        return {
            email: '',
            password: '',
            alert: false,
            passwordType: 'password',
            showForgotPassword:false,
        }
    },

    methods: {
        ...Vuex.mapActions('login', {
            logMeIn: 'logMeIn',
            askForPasswordReset: 'askForPasswordReset',
            logout: 'logout'
        }),
        async connexion() {
            this.alert = false;
            await this.logMeIn([this.email, this.password]).then(() => {
                    if (this.authStatus.message && (this.authStatus.message.message === 'User doesn\'t exist' || this.authStatus.message.message === 'Invalid credentials')) {
                        this.alert = true
                    }
                }
            );
        },
        async resetPassword() {
            let query = this.generateQueryString()
            await this.askForPasswordReset(query);
        },
        generateQueryString(){
            let data = []
            let fields = new FormData();
            fields.set('email', this.email)
            data.push(fields)
            return fields
        },
        capitalize (value){
            return this.$options.filters.capitalize(value)
        }

    },
    computed: {
        ...Vuex.mapGetters('login', {
            authStatus: 'authStatus',
            connexionIsLoading: 'getConnexionIsLoading',
            resetMailSent: 'resetMailSent'
        }),
    },
    watch: {
        resetMailSent: function (val) {
            if (val){
                this.showForgotPassword = true
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.login-card {
    margin: 15vh 0;
    padding: 1vh 0;
    max-width: 500px;
    background-color: white;
    border-radius: 10px;
}
.login-input {
    background-color: white;
    border: none;
    color:$light-grey;
    box-shadow: none !important;
    border-bottom: 1px solid $light-grey;
    border-radius: 0;
    &:active{
        color: #28AAEA;
        border-bottom: 1px solid #28AAEA;
        box-shadow: none !important;
    }
    &:focus{
        color: #28AAEA;
        border-bottom: 1px solid #28AAEA;
        box-shadow: none !important;
    }
}

.language-selector__container {
    select {
        background-color: white;
        border: 1px solid rgb(197, 197, 197);
        border-radius: 5px;
        box-sizing: border-box;
        color: #3E3D40;
        display: block;
        font-family: SourceSansPro, Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-weight: 600;
        height: 40px;
        min-width: 80px;
        padding: 0 20px 0 16px;
        outline: none;
    }
    label {
        position:relative
    }

    label:after {
        content:'';
        left: 54px;
        top: 17px;
        padding:0 0 2px;
        position:absolute;
        pointer-events:none;
        width: 0;
        height: 0;
        border-left: 3.5px solid transparent;
        border-right: 3.5px solid transparent;
        border-top: 5px solid #007fd8;
        z-index: 100;
    }

    label:before {
        content:'';
        left: 56px;
        top: 10px;
        width: 20px;
        height: 20px;
        background: white;
        position: absolute;
        pointer-events: none;
        display: block;
        z-index: 1;
    }
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>

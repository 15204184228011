<template>
    <div>
        <div class="row align-items-center align-content-center">
            <div class="col-xl-6 float-left">
                <div class="rounded float-left">
                    <div
                            v-b-toggle.sidebar-no-header
                            class="my-1 rounded shadow p-2 menu-item-style d-flex"
                    >
                        <b-icon icon="justify" aria-hidden="true" class="float-right align-self-center align-items-center align-content-center"></b-icon>
                        MENU
                    </div>
                </div>

            </div>
            <div class="col-xl-6">
                <img
                        alt="LifyAir logo"
                        src="../../src/assets/logo.svg"
                        class="rounded float-right img-fluid"
                        style="max-height: 8vh; cursor: pointer;"
                        @click="sendToLify('https://www.lifyair.com')"
                >
            </div>

        </div>
        <AirQualityDashboard/>
    </div>
</template>

<script>
// @ is an alias to /src
import AirQualityDashboard from '@/components/AirQualityDashboard.vue'

export default {
  name: 'Home',
  components: {
      AirQualityDashboard
  }
}
</script>

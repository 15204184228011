<template>
    <div>
        <div class="mb-3">
            <p class="h6">
                You can download raw data day per day by choosing file in the list below &nbsp;

                <b-icon icon="info-circle-fill" id="info-icon-data-manager" variant="primary" scale="1.5"></b-icon>
                <b-popover target="info-icon-data-manager" triggers="hover" placement="rightbottom" variant="primary">
                    Informations for data file reading : <br>
                    LOAC V1.2 <br>
                    <strong> Size classes (micrometers) </strong>:
                    <br> 0.2-0.3 | 0.3-0.4 | 0.4-0.5 | 0.5-0.6 | 0.6-0.7 |
                    0.7-0.9 | 0.9-1.1 | 1.1-3. | <br> 3.-5. | 5.-7.5 |
                    7.5-10 | 10.-12.5 |<br> 12.5-15. | 15.-17.5 |
                    17.5-20. | 20.-22. | <br>   22.-30. |  30.-40. |  40.-50. |
                    <br>
                    <strong>Column 13 to 31 </strong>: <br> Concentrations for the 19 size classes in nb/cm-3
                </b-popover>
            </p>
            <br>
        </div>
<!--        <div>-->
<!--            <label><b>Choose a date :</b></label>-->
<!--            <b-form-datepicker v-model="startDate" class="mb-2" ></b-form-datepicker>-->
<!--            <b-button-->
<!--                v-if="dataList.length > 0 && loaded"-->
<!--                @click.prevent="downloadFile()"-->
<!--            > DOWNLOAD FILE  <b-icon icon="cloud-download" aria-hidden="true" class="float-left"></b-icon></b-button>-->
<!--            <div class="d-flex justify-content-center mb-3" v-else-if="!loaded && dateOfContent">-->
<!--                <b-spinner label="Loading..."></b-spinner>-->
<!--            </div>-->
<!--            <div v-else-if="loaded && dateOfContent">-->
<!--                No Content for this Day-->
<!--            </div>-->
<!--        </div>-->
        <div class="rounded bg-blue">
            <b-form-group class="p-1 clr-white">
                <p>Available File Types</p>
                <b-form-radio v-model="csvType"  name="conc-radios" value="conc">Concentration Files</b-form-radio>
                <b-form-radio v-model="csvType"  name="mass-radios" value="masse">Mass Files</b-form-radio>
            </b-form-group>
        </div>

        <label><b>Files :</b></label>

        <div class="border container-fluid" v-if="dataList.length > 0 && loaded">
            <div class="row bg-light-grey rounded d-flex align-items-center align-content-center justify-content-center mb-0">
                <div class="col-xl-4"><p class="mb-0 my-2">File Name </p></div>
                <div class="col-xl-5" ><p class="mb-0 my-2 add-cursor" @click="sortData()">Last Modified
                    <b-icon variant="white" :icon="sortedUp ? 'chevron-down':'chevron-up'"></b-icon> </p></div>
                <div class="col-xl-2"><p class="mb-0 my-2">File Size </p></div>
                <div class="col-xl-1"><p class="mb-0 my-2">Download</p></div>
            </div>
            <div
                v-for="(item,i) in dataList"
                :key="i"
                class="row align-content-center file-item-style p-1 d-flex align-items-center align-content-center justify-content-center"
                @click="downloadFile(item.Key)"
            >
                <div class="col-xl-4"><p>{{ item.Key | getFileName }} </p></div>
                <div class="col-xl-5"><p>{{ item.LastModified }} </p></div>
                <div class="col-xl-2"><p>{{ item.Size / 1000 }} KB</p></div>
                <div class="col-xl-1">
                    <b-icon icon="cloud-download" variant="primary" scale="1.5"></b-icon>
                </div>
                <hr v-if="i !== (dataList.length - 1)">
            </div>

<!--            <b-form-datepicker v-model="startDate" class="mb-2" ></b-form-datepicker>-->
<!--            <b-button-->
<!--                v-if="dataList.length > 0 && loaded"-->
<!--                @click.prevent="downloadFile()"-->
<!--            > DOWNLOAD FILE  <b-icon icon="cloud-download" aria-hidden="true" class="float-left"></b-icon></b-button>-->
<!--            <div class="d-flex justify-content-center mb-3" v-else-if="!loaded && dateOfContent">-->
<!--                <b-spinner label="Loading..."></b-spinner>-->
<!--            </div>-->
<!--            <div v-else-if="loaded && dateOfContent">-->
<!--                No Content for this Day-->
<!--            </div>-->
        </div>
        <div class="border container-fluid" v-else-if="dataList.length === 0 && loaded">
            <div
                    class="row align-content-center file-item-style p-1 d-flex align-items-center align-content-center justify-content-center"
            >
                <div class="col-xl-12"><p>No data for this sensor </p></div>
            </div>
        </div>
        <div v-else>
            <b-spinner variant="primary"></b-spinner>
        </div>
    </div>
</template>

<script>
    import AWS from "aws-sdk";

    export default {
        props:{
            selectedSensor: String,
        },
        name: "fileModal",
        filters: {
            getFileName: function (str) {
                const file = str.split('/')
                if (file[3]){
                    return file[3]
                } else {
                    return file[2]
                }
            }
        },
        data() {
            return {
                variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
                headerBgVariant: 'dark',
                headerTextVariant: 'light',
                bodyBgVariant: 'light',
                bodyTextVariant: 'dark',
                footerBgVariant: 'warning',
                footerTextVariant: 'dark',
                dataS3:null,
                csvType:'conc',
                error:[],
                dataList:[],
                startDate:null,
                isMyContentLoaded:false,
                chevronDown:false
            }
        },
        methods:{
            async getFileListWithData(){
                AWS.config.update({
                    accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
                    secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
                });

                let s3 = new AWS.S3();
                let prefix = this.selectedSensor.toString()+'/'+ this.csvType
                let paramsListObject = {
                    Bucket:	'loac-data-csv',
                    Prefix: prefix,
                    MaxKeys: 100
                }

                let data = await s3.listObjects(paramsListObject).promise();
                for (let index = 0; index < data.Contents.length; index++) {
                    this.dataList.push(data.Contents[index])
                }
                this.dataList = this.dataList.reverse()
            },
            async getFileList(){
                AWS.config.update({
                    accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
                    secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
                });

                let s3 = new AWS.S3();
                let prefix = this.selectedSensor.toString()+'/'+ this.csvType
                let paramsListObject = {
                    Bucket:	'loac-data-csv',
                    Prefix: prefix,
                    MaxKeys: 100
                }

                let data = await s3.listObjects(paramsListObject).promise();
                data.Contents.filter(item => item.Key.endsWith('csv'))
                for (let index = 0; index < data.Contents.length; index++) {
                    if (data.Contents[index]['Size']) {
                        this.dataList.push(data.Contents[index])
                    }
                }
                this.dataList = this.dataList.reverse()
            },
            async downloadFile(key){
                let s3 = new AWS.S3();

                if (this.dataList[0]){
                    let paramsGetObject = {
                        Bucket:	'loac-data-csv',
                        // Prefix: prefix,
                        Key: key
                    }

                    let myFile = await s3.getObject(paramsGetObject).promise();
                    const blob = new Blob([myFile.Body], { type: 'binary/octet-stream' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = key.substring(25)
                    link.click()
                    URL.revokeObjectURL(link.href)
                }
            },
            sortData(){
                this.chevronDown = !this.chevronDown
                this.dataList = this.dataList.reverse()
            }
        },
        watch: {
            startDate: async function () {
                this.dataList = []
                this.isMyContentLoaded = false
                await this.getFileList()
                this.isMyContentLoaded = true
            },
            csvType: async function (){
                this.isMyContentLoaded = false
                this.dataList = []
                await this.getFileList()
                this.isMyContentLoaded = true
            }
        },
        computed:{
            sensor(){
                return this.selectedSensor
            },
            content(){
                return this.dataList
            },
            dateOfContent(){
                return this.startDate
            },
            loaded(){
                return this.isMyContentLoaded
            },
            sortedUp(){
                return this.chevronDown
            }
        },
        async created() {
            this.isMyContentLoaded = false
            await this.getFileList()
            this.isMyContentLoaded = true
        }
    }
</script>

<style lang="scss" scoped>
    .add-cursor{
        cursor: pointer;
    }
    .file-item-style{
        cursor: pointer;
        color: #7b7c7d;
        background: white;
    &:hover{
         color: #62c4e8;
     }
    }
</style>
import moment from "moment";

export default {
    PM,
    Alti
}

function PM(data,pm, isMobile = true){
    let formattedData = {
        groundData: [],
        inflightData: []
    };

    switch (pm) {
        case 'pm1':
            for (let i = 0;i < data.length ; i++){
                console.log(data[i].altitude)
                if (data[i].altitude <= 40 ){
                    formattedData.groundData.push([toLocalDate(data[i].createdAt),data[i].PM1])
                } else if(data[i].altitude > 40 && isMobile) {
                    formattedData.inflightData.push([toLocalDate(data[i].createdAt),data[i].PM1])
                }
            }
            break;
        case 'pm2_5':
            for (let i = 0;i < data.length ; i++){
                if (data[i].altitude <= 40 ){
                    formattedData.groundData.push([toLocalDate(data[i].createdAt),data[i].PM2_5])
                } else if(data[i].altitude > 40 && isMobile) {
                    formattedData.inflightData.push([toLocalDate(data[i].createdAt),data[i].PM2_5])
                }
            }
            break;
        case 'pm10':
            for (let i = 0;i < data.length ; i++) {
                if (data[i].altitude <= 40) {
                    formattedData.groundData.push([toLocalDate(data[i].createdAt), data[i].PM10])
                } else if(data[i].altitude > 40 && isMobile) {
                    formattedData.inflightData.push([toLocalDate(data[i].createdAt), data[i].PM10])
                }
            }
            break;
        case 'mass2_5':
            for (let i = 0;i < data.length ; i++) {
                if (data[i].altitude <= 40) {
                    formattedData.groundData.push([toLocalDate(data[i].createdAt), data[i].MASS2_5])
                } else if(data[i].altitude >= 80 && isMobile) {
                    formattedData.inflightData.push([toLocalDate(data[i].createdAt), data[i].MASS2_5])
                }
            }
            break;
        case 'mass10':
            for (let i = 0;i < data.length ; i++) {
                if (data[i].altitude <= 40) {
                    formattedData.groundData.push([toLocalDate(data[i].createdAt), data[i].MASS10])
                } else if(data[i].altitude >= 80 && isMobile) {
                    formattedData.inflightData.push([toLocalDate(data[i].createdAt), data[i].MASS10])
                }
            }
            break;
        default:
            console.log(`Sorry, we are out particules`);
    }

    return formattedData
}

function Alti(data){

    let PM1 = []
    let PM2_5 = []
    let PM10 = []
    let fk = Object.entries(data)

    for (let i = 0 ; i < fk.length ; i++){
        PM1.push([fk[i][1].PM1,parseInt(fk[i][0])])
        PM2_5.push([fk[i][1].PM2_5,parseInt(fk[i][0])])
        PM10.push([fk[i][1].PM10,parseInt(fk[i][0])])
    }

    return [PM1,PM2_5,PM10]
}

function toLocalDate(date){
    let utcDate = moment.utc(date).toDate();
    var stillUtc = moment(utcDate).local();
    return moment(stillUtc).unix() * 1000
}
<template>
    <highcharts class="hc border" :options="options" ref="chart" v-if="!loadingStatus"></highcharts>
</template>

<script>
import Vuex from 'vuex'
import format from "@/services/data/format";
export default {
    props: {
        chartTitle: String,
        yTitle: {
            type : String,
            default: ''
        },
        xTitle: {
            type:String,
            default:'Timestamp (minutes)'
        },
        dataType:String,
        isMobile: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            options: {
                chart:{
                    backgroundColor:'white',
                    borderColor:'black',
                    tension:1
                },
                title: {
                    text: this.chartTitle,
                    x: -20 //center
                },
                subtitle: {
                    text: 'Source : LOAC',
                    x: -20
                },
                xAxis: {
                    type: 'datetime',
                    dateTimeLabelFormats: { // don't display the dummy year\
                        day: '%e. %b',
                    },
                    title: {
                        text: this.xTitle
                    },
                },
                yAxis: {
                    type: 'logarithmic',
                    minorTickInterval: 1,
                    accessibility: {
                        rangeDescription: 'Range: 0.1 to 10000000'
                    },
                    title: {
                        text: this.yTitle
                    },
                    min:1,
                    max: 10000000
                },
                tooltip: {
                    valueSuffix: ' nb/L'
                },
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                    borderWidth: 0
                },
                credits: {
                    enabled: false
                },
            },
            loaded:false
        }
    },
    methods:{
        getRandomArbitrary(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        getRgbaRandomColor(){
            let color = [
                ['#008dcd','#f76ebb'],
                ['#c80050','#ffa600'],
                ['#fe755d','#3c8dff'],
                ['#143D59','#F4B41A'],
                ['#7ADAC0','#210070'],
                ['#2E302B','#FA712A'],
                ['#5C0E2F','#FBA981'],
                ['#f76ebb','#008dcd'],
                ['#ffa600','#c80050'],
                ['#3c8dff','#fe755d'],
                ['#F4B41A','#143D59'],
                ['#210070','#7ADAC0'],
                ['#FA712A','#2E302B'],
                ['#FBA981','#5C0E2F'],
            ]
            return color[this.getRandomArbitrary(0,13)]
        },
        setSeriesMobile(){
            let data = format.PM(this.reports.pmConc, this.dataType)

            let firstSeries = data.groundData
            let secondSeries = data.inflightData

            this.loaded = true
            let colors = this.getRgbaRandomColor();
            return [
                {
                    name: 'Particules from '+ this.chartTitle +' - ground',
                    data: firstSeries,
                    type: 'spline',
                    color: colors[0]
                },
                {
                    name: 'Particules from '+ this.chartTitle +' - inflight',
                    data: secondSeries,
                    type: 'spline',
                    color: colors[1]
                }

            ]

        },
        setSeries(){
            let data = format.PM(this.reports.pmConc, this.dataType)
            let firstSeries = data.groundData

            this.loaded = true
            let colors = this.getRgbaRandomColor();
            return [
                {
                    name: 'Particules from '+ this.chartTitle +' - ground',
                    data: firstSeries,
                    type: 'spline',
                    color: colors[0]
                }
            ]

        }
    },
    created() {
        if (this.isMobile){
            this.options.series = this.setSeriesMobile()
        } else {
            this.options.series = this.setSeries()
        }
        if (this.dataType === 'pm1'){
            this.options.yAxis = {
                type: 'logarithmic',
                minorTickInterval: 1,
                accessibility: {
                    rangeDescription: 'Range: 0.1 to 10000000'
                },
                title: {
                    text: 'Concentration (nb/Liter)'
                },
                min:10000,
            }
        } else if (this.dataType === 'pm2_5'){
            this.options.yAxis = {
                minorTickInterval: 1,
                accessibility: {
                    rangeDescription: 'Range: 0.1 to 1000'
                },
                title: {
                    text: 'Concentration (nombre/Litre)'
                },
                // min:1,
                // max: 1000
            }
        } else if(this.dataType === 'pm10'){
            this.options.yAxis = {
                // type: 'logarithmic',
                minorTickInterval: 1,
                accessibility: {
                    rangeDescription: 'Range: 0.1 to 100'
                },
                title: {
                    text: 'Concentration (nombre/Litre)'
                },
                min:1,
                // max: 100
            }
        }  else if(this.dataType === 'mass2_5'){
            this.options.yAxis = {
                type: 'logarithmic',
                minorTickInterval: 1,
                accessibility: {
                    rangeDescription: 'Range: 0.1 to 100'
                },
                title: {
                    text: 'Mass (μg/m3)'
                },
                min:1,
                // max: 100
            }
        }  else if(this.dataType === 'mass10'){
            this.options.yAxis = {
                type: 'logarithmic',
                minorTickInterval: 1,
                accessibility: {
                    rangeDescription: 'Range: 0.1 to 100'
                },
                title: {
                    text: 'Mass (μg/m3)'
                },
                min:1,
                // max: 100
            }
        }

    },
    computed:{
        ...Vuex.mapGetters('report',{
            reports:'reports',
            loadingStatus:'loadingStatus'
        }),
        isLoaded(){
            return this.loaded
        }
    },
    watch:{
        'this.reports'(){
            this.options.series = this.setSeries()
        },
    },
};
</script>

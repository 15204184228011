<template>
    <div>
        <article v-if="type === 'maintenance'">
            <h1>We’ll be back soon!</h1>
            <div>
                <p>Sorry for the inconvenience but we’re performing some maintenance at the moment. If you need to you
                    can always <a href="mailto:dev@lifyair.com" target="_blank">contact us</a>, otherwise we’ll be back
                    online shortly!</p>
                <p>— The Lify-air Team</p>
            </div>
        </article>
        <article v-else>
            <h1>404</h1>
            <div>
                <p>Oops, the page you were looking for doesn’t exist</p>
                <a href="/">Back to home</a>
            </div>
        </article>
        <img src="@/assets/images/Logo_LifyAir.png" alt="logo lifyair" class="lifyair-logo"/>
    </div>
</template>

<script>
export default {
    name: "Error",
    props: {
        type: {
            type: String,
            default: 'notFound'
        }
    }
}
</script>

<style scoped>
h1 {
    font-size: 3rem;
    margin-top: 30Vh;
}

body {
    text-align: center;
    padding: 20px;
    font: 2rem Helvetica, sans-serif;
    color: #333;
}

a {
    color: #dc8100;
    text-decoration: none;
}

a:hover {
    color: #333;
    text-decoration: none;
}

article {
    display: block;
    text-align: left;
    width: 750px;
    margin: 0 auto;
}

@media only screen and (max-width: 600px) {
    body {
        padding-top: 150px;
    }

    article {
        width: auto;
        text-align: center;
    }

    h1 {
        font-size: 2rem;
    }
}

.lifyair-logo {
    width: 200px;
    position: absolute;
    bottom: 5px;
    right: 20px;
}
</style>
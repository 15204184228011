<template>
    <highcharts class="hc border" :options="options" ref="chart"></highcharts>
</template>

<script>
import Vuex from "vuex";
import format from "@/services/data/format";
import Highcharts from "highcharts"

export default {
    data() {
        return {
            loaded:false,
            options: {
                noData:{
                    attr:undefined,
                    position:{
                        align:'center',
                        verticalAlign:'middle',
                        x:0,
                        y:0
                    },
                    style:undefined,
                    useHTML:false
                },
                chart:{
                    backgroundColor:'white',
                    borderColor:'black'
                },
                title: {
                    text: 'Concentration as a function of Altitude',
                    x: -20 //center
                },
                subtitle: {
                    text: 'Source : LOAC',
                    x: -20
                },
                xAxis: {
                    type: 'logarithmic',
                    title: {
                        text: 'Number of Particles (nb/Liter)'
                    },
                    minorTickInterval: 0.1,
                    accessibility: {
                        rangeDescription: 'Range: 0.1 to 1000'
                    },
                    min:0.1,
                },
                yAxis: {
                    title: {
                        text: 'Altitude (in meter)'
                    },
                    // plotLines: [{
                    //     value: 0,
                    //     width: 1,
                    //     color: '#808080'
                    // }],
                },
                tooltip: {
                    valueSuffix: 'm',
                    shared:true,
                    formatter: function() {
                        var point = this.points[0];
                        return '<b>' + point.series.name + '</b><br/>' + 'Measuring altitude : ' + this.y + ' m<br/>' +
                            'Number of Particles : ' + Highcharts.numberFormat(point.x, 0) + ' particles/Liter';
                    },
                },
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom',
                    borderWidth: 0
                },
                series: [],
                credits: {
                    enabled: false
                },
            }
        }
    },
    methods:{
        getRandomArbitrary(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        getRgbaRandomColor(){
            return this.getRandomArbitrary(0,255)+','+this.getRandomArbitrary(0,255)+','+this.getRandomArbitrary(0,255)
        },
        setSeries(){
            this.loaded = true
            let data = format.Alti(this.reports.altiConc)
            return [
                {
                    name: 'Particules de 0.2 à 1µm',
                    data: data[0],
                    type: 'spline',
                    color:'#003f5c'
                },
                {
                    name: 'Particules de 1 à 2.5µm',
                    data: data[1],
                    type: 'spline',
                    color:'#bc5090'
                },
                {
                    name: 'Particules de 2.5 à 10µm',
                    data: data[2],
                    type: 'spline',
                    color:'#ff6361'
                }
            ]
        }
    },
    computed:{
        ...Vuex.mapGetters('report',{
            reports:'reports',
            loadingStatus:'loadingStatus'
        }),
        isLoaded(){
            return this.loaded
        },
        dynamicOptions(){
            return this.options
        }
    },
    created() {
        this.options.series = this.setSeries()
    },
    watch:{
        'this.reports'(){
            this.options.series = this.setSeries()
        },
    },
};
</script>
